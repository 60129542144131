/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable import/no-unresolved */
import React, { useContext } from 'react';
import LazyHydrate from 'react-lazy-hydration';
import styled, { createGlobalStyle } from 'styled-components';

import { GoogleFonts } from '@nfs-react/components';

import { FeaturesContext } from 'helpers/providers/FeaturesProvider';
import { useScroll } from 'hooks';

import {
  Top,
  Quotes,
  Form,
  Features,
  CTA,
  RangeSlider,
  Article,
  Calculator,
} from './components';

const App = () => {
  const [scrollToForm, formRef] = useScroll();
  const [scrollToCalc, calcRef] = useScroll();

  const features = useContext(FeaturesContext);
  const isCPR = features.includes('version_2_cpr');
  const isPCO = features.includes('version_3_pco');
  const isCCA = features.includes('version_4_cca');
  const isEUS = features.includes('version_5_eus');
  const isGTC = features.includes('version_6_gtc');
  const isMCI = features.includes('version_7_mci');
  const isGPA = features.includes('version_8_gpa');
  const isBDQ = features.includes('version_9_bdq');
  const isAGT = features.includes('version_10_agt');
  const isVLE = features.includes('version_11_vle');
  const isITU = features.includes('version_12_itu');
  const isBDO = features.includes('version_13_bdo');
  const isAMB = features.includes('version_14_amb');
  const isBJS = features.includes('version_15_bjs');
  const isMBR = features.includes('version_16_mbr');
  const isCLO = features.includes('version_17_clo');
  const isMLZ = features.includes('version_18_mlz');
  const isBTH = features.includes('version_19_bth');
  const isVAK = features.includes('version_20_vak');
  const isCCF = features.includes('version_21_ccf');
  const isHER = features.includes('version_22_her');
  const isBGQ = features.includes('version_23_bgq');
  const isGDG = features.includes('version_24_gdg');
  const isBIL = features.includes('version_25_bil');
  const isMCD = features.includes('version_26_mcd');
  const isBAA = features.includes('version_27_baa');
  const isGTA = features.includes('version_28_gta');
  const isBCN = features.includes('version_29_bcn');
  const isGPE = features.includes('version_30_gpe');
  const isGAV = features.includes('version_31_gav');
  const isSBM = features.includes('version_32_sbm');
  const isCRD = features.includes('version_33_crd');
  const isGPM = features.includes('version_34_gpm');
  const isBMG = features.includes('version_35_bmg');
  const isFRR = features.includes('version_36_frr');
  const isVCM = features.includes('version_37_vcm');
  const isCBJ = features.includes('version_38_cbj');
  const isGMY = features.includes('version_39_gmy');
  const isIPC = features.includes('version_40_ipc');
  const isPDP = features.includes('version_41_pdp');
  const isMVL = features.includes('version_42_mvl');
  const isGBC = features.includes('version_43_gbc');
  const isGOA = features.includes('version_44_goa');
  const isPTR = features.includes('version_45_ptr');
  const isDPN = features.includes('version_46_dpn');
  const isFFC = features.includes('version_47_ffc');
  const isAMR = features.includes('version_48_amr');
  const isBCD = features.includes('version_49_bcd');
  const isKMC = features.includes('version_50_kmc');
  const isLGT = features.includes('version_51_lgt');
  const isLUH = features.includes('version_52_luh');
  const isCPL = features.includes('version_53_cpl');
  const isNFS = features.includes('version_54_nfs');
  const isIBS = features.includes('version_55_ibs');
  const isBFG = features.includes('version_56_bfg');
  const isACC = features.includes('version_57_acc');
  const isNDO = features.includes('version_58_ndo');
  const isFDB = features.includes('version_59_fdb');
  const isMDL = features.includes('version_60_mdl');
  const isLIR = features.includes('version_61_lir');
  const isMCDV2 = features.includes('version_62_mcd');
  const isTGO = features.includes('version_63_tgo');
  const isPTRL = features.includes('version_64_ptrl');
  const isLISP = features.includes('version_65_lisp');
  const isRDT = features.includes('version_66_rdt');
  const isRDT2 = features.includes('version_67_rdt');
  const isSQQM = features.includes('version_68_sqqm');
  const isCNDC = features.includes('version_69_cndc');
  const isCNDC2 = features.includes('version_70_cndc');
  const isSHN = features.includes('version_71_shn');
  const isBNP = features.includes('version_72_bnp');
  const isJUM = features.includes('version_73_jum');
  const isBDB = features.includes('version_74_bdb');
  const isPHO = features.includes('version_75_pho');
  const isGLD = features.includes('version_76_gld');
  const isOIL = features.includes('version_77_oil');
  const isGAS = features.includes('version_78_gas');
  const isOIL2 = features.includes('version_79_oil');
  const isGLD2 = features.includes('version_80_gld');
  const isGAS2 = features.includes('version_81_gas');
  const isCMX = features.includes('version_82_cmx');
  const isPMN = features.includes('version_83_pmn');
  const isANM = features.includes('version_84_anm');
  const isMAZ = features.includes('version_85_maz');
  const isARCR = features.includes('version_86_arcr');
  const isDPNS = features.includes('version_87_dpns');
  const isGRCL = features.includes('version_88_grcl');
  const isYPF = features.includes('version_89_ypf');
  const isANTM = features.includes('version_90_antm');
  const isSTD = features.includes('version_91_std');
  const isCPC = features.includes('version_92_cpc');
  const isLPP = features.includes('version_93_lpp');
  const isENM = features.includes('version_94_enm');
  const isLVRP = features.includes('version_95_lvrp');
  const isBNT = features.includes('version_96_bnt');
  const isMEC = features.includes('version_97_mec');
  const isPCA = features.includes('version_98_pca');
  const isPRX = features.includes('version_99_prx');
  const isDIS = features.includes('version_100_dis');
  const isMCS = features.includes('version_101_mcs');
  const isNVI = features.includes('version_102_nvi');
  const isNIK = features.includes('version_103_nik');
  const isAPL = features.includes('version_104_apl');
  const isVST = features.includes('version_105_vst');
  const isGRC = features.includes('version_106_grc');
  const isGMA = features.includes('version_107_gma');
  const isSOG = features.includes('version_108_sog');
  const isSLR = features.includes('version_109_slr');
  const isPTRB = features.includes('version_110_ptrb');

  const removeRangeSlider = features.includes('remove_range_slider');
  const addArticle = features.includes('add_article');
  const removeFeatures = features.includes('remove_features');
  const removeCTA = features.includes('remove_cta');
  const addCalculator = features.includes('add_calculator');

  let primaryColor1;
  let primaryColor2;
  let textColor = '#fff';
  let prefix;
  let brand;

  if (isCPR) {
    primaryColor1 = '#007D2D';
    primaryColor2 = '#007D2D';
    prefix = '_v2';
    brand = 'Cementos Progreso';
  } else if (isPCO) {
    primaryColor1 = '#FFDE00';
    primaryColor2 = '#ED2D24';
    textColor = '#000';
    prefix = '_v3';
    brand = 'Pollo Campero';
  } else if (isCCA) {
    primaryColor1 = '#ED2D24';
    primaryColor2 = '#ED2D24';
    prefix = '_v4';
    brand = 'Cervecería Centroamericana';
  } else if (isEUS) {
    primaryColor1 = '#FFD000';
    primaryColor2 = '#FFD000';
    textColor = '#000';
    prefix = '_v5';
    brand = 'Emisoras Unidas';
  } else if (isGTC) {
    primaryColor1 = '#0069BB';
    primaryColor2 = '#FB002F';
    prefix = '_v6';
    brand = 'Banco G&T Continental';
  } else if (isMCI) {
    primaryColor1 = '#53585B';
    primaryColor2 = '#FF9317';
    prefix = '_v7';
    brand = 'CMI';
  } else if (isGPA) {
    primaryColor1 = '#007333';
    primaryColor2 = '#60C521';
    prefix = '_v8';
    brand = 'Banco Promerica';
  } else if (isBDQ) {
    primaryColor1 = '#253C76';
    primaryColor2 = '#253C76';
    prefix = '_v9';
    brand = 'Banco del Quetzal';
  } else if (isAGT) {
    primaryColor1 = '#0064FF';
    primaryColor2 = '#0064FF';
    prefix = '_v10';
    brand = 'Telefónica Guatemala';
  } else if (isVLE) {
    primaryColor1 = '#277e7a';
    primaryColor2 = '#ECB11F';
    prefix = '_v11';
    brand = 'Vale';
  } else if (isITU) {
    primaryColor1 = '#EC7000';
    primaryColor2 = '#EC7000';
    prefix = '_v12';
    brand = 'Itaú Unibanco';
  } else if (isBDO) {
    primaryColor1 = '#CC0930';
    primaryColor2 = '#B81570';
    prefix = '_v13';
    brand = 'Banco Bradesco';
  } else if (isAMB) {
    primaryColor1 = '#79111E';
    primaryColor2 = '#BA0C2F';
    prefix = '_v14';
    brand = 'Ambev';
  } else if (isBJS) {
    primaryColor1 = '#17358F';
    primaryColor2 = '#409BEA';
    prefix = '_v15';
    brand = 'JBS';
  } else if (isMBR) {
    primaryColor1 = '#0A1A5D';
    primaryColor2 = '#2467B5';
    prefix = '_v16';
    brand = 'Embraer';
  } else if (isCLO) {
    primaryColor1 = '#3DAEEF';
    primaryColor2 = '#1C416A';
    prefix = '_v17';
    brand = 'Cielo';
  } else if (isMLZ) {
    primaryColor1 = '#3386FF';
    primaryColor2 = '#2568c3';
    prefix = '_v18';
    brand = 'Magalu';
  } else if (isBTH) {
    primaryColor1 = '#11377B';
    primaryColor2 = '#236AA6';
    prefix = '_v19';
    brand = 'B3';
  } else if (isVAK) {
    primaryColor1 = '#3374DB';
    primaryColor2 = '#77B4F3';
    prefix = '_v20';
    brand = 'Kavak';
  } else if (isCCF) {
    primaryColor1 = '#ec3e42 ';
    primaryColor2 = '#474747';
    prefix = '-ccf';
    brand = 'FAVORITA';
  } else if (isHER) {
    primaryColor1 = '#1d4370';
    primaryColor2 = '#04bbf1 ';
    prefix = '-her';
    brand = 'Holcim Ecuador';
  } else if (isBGQ) {
    primaryColor1 = '#d2006e ';
    primaryColor2 = '#160f41';
    prefix = '-bgq';
    brand = 'Banco Guayaquil';
  } else if (isGDG) {
    primaryColor1 = '#2c8d93';
    primaryColor2 = '#0f1e2c  ';
    prefix = '-gdg';
    brand = 'Grupo Industrial Graiman';
  } else if (isBIL) {
    primaryColor1 = '#ea8e29';
    primaryColor2 = '#606060 ';
    prefix = '-bil';
    brand = 'Banco Internacional';
  } else if (isMCD) {
    primaryColor1 = '#FFBC04';
    primaryColor2 = '#2D3277';
    prefix = '-mcd';
    brand = 'MERCADO LIVRE';
  } else if (isBAA) {
    primaryColor1 = '#fdda24';
    primaryColor2 = '#1a1b1a';
    prefix = '-baa';
    brand = 'Banco Agrícola';
  } else if (isGTA) {
    primaryColor1 = '#576f9b';
    primaryColor2 = '#2D3277';
    prefix = '-gta';
    brand = 'Grupo Terra';
  } else if (isBCN) {
    primaryColor1 = '#01426a';
    primaryColor2 = '#d6b171';
    prefix = '-bcn';
    brand = 'Banco Cuscatlán';
  } else if (isGPE) {
    primaryColor1 = '#566c10';
    primaryColor2 = '#231f20';
    prefix = '-gpe';
    brand = 'Grupo Roble';
  } else if (isGAV) {
    primaryColor1 = '#0067ab';
    primaryColor2 = '#d12b0c';
    prefix = '-gav';
    brand = 'Grupo Aval';
  } else if (isSBM) {
    primaryColor1 = '#a79064';
    primaryColor2 = '#ff5733';
    prefix = '-sbm';
    brand = 'SABMiller';
  } else if (isCRD) {
    primaryColor1 = '#b3ad01';
    primaryColor2 = '#026684';
    prefix = '-crd';
    brand = 'Credivalores';
  } else if (isGPM) {
    primaryColor1 = '#354652';
    primaryColor2 = '#000000';
    prefix = '-gpm';
    brand = 'Grupo Poma';
  } else if (isBMG) {
    primaryColor1 = '#288777';
    primaryColor2 = '#5d6470';
    prefix = '-bmg';
    brand = 'Buenaventura';
  } else if (isFRR) {
    primaryColor1 = '#2ea143';
    primaryColor2 = '#1c1c1b';
    prefix = '-frr';
    brand = 'Ferreycorp';
  } else if (isVCM) {
    primaryColor1 = '#fc5701';
    primaryColor2 = '#101d2a';
    prefix = '-vcm';
    brand = 'Volcan';
  } else if (isCBJ) {
    primaryColor1 = '#f4e00f';
    primaryColor2 = '#000000';
    textColor = '#000';
    prefix = '-cbj';
    brand = 'Backus';
  } else if (isGMY) {
    primaryColor1 = '#ED8000';
    primaryColor2 = '#000000';
    prefix = '-gmy';
    brand = 'Graña y Montero';
  } else if (isIPC) {
    primaryColor1 = '#777777';
    primaryColor2 = '#0457a4';
    prefix = '-ipc';
    brand = 'InRetail';
  } else if (isPDP) {
    primaryColor1 = '#e2211c';
    primaryColor2 = '#007ac3';
    prefix = '-pdp';
    brand = 'Petroperú';
  } else if (isMVL) {
    primaryColor1 = '#ff4713';
    primaryColor2 = '#981d97';
    prefix = '-mvl';
    brand = 'Movil';
  } else if (isGBC) {
    primaryColor1 = '#0047bb';
    primaryColor2 = '#f02bc2';
    prefix = '-gbc';
    brand = 'Global Bank Corp';
  } else if (isGOA) {
    primaryColor1 = '#084a79';
    primaryColor2 = '#21b8f1';
    prefix = '-goa';
    brand = 'Grupo Assa';
  } else if (isPTR) {
    primaryColor1 = '#009641';
    primaryColor2 = '#09152e';
    prefix = '-ptr';
    brand = 'Petroterminal de Panama';
  } else if (isDPN) {
    primaryColor1 = '#00943a';
    primaryColor2 = '#000000';
    prefix = '-dpn';
    brand = 'Dos Pinos';
  } else if (isFFC) {
    primaryColor1 = '#0071CE';
    primaryColor2 = '#434F57';
    prefix = '-ffc';
    brand = 'FIFCO';
  } else if (isAMR) {
    primaryColor1 = '#28a745';
    primaryColor2 = '#dc3545';
    prefix = '-amr';
    brand = 'Auto Mercado';
  } else if (isBCD) {
    primaryColor1 = '#e4002b';
    primaryColor2 = '#1075bb';
    prefix = '-bcd';
    brand = 'Bac Credomatic';
  } else if (isKMC) {
    primaryColor1 = '#0f059e';
    primaryColor2 = '#000000';
    prefix = '-kmc';
    brand = 'Kimberly Clark';
  } else if (isLGT) {
    primaryColor1 = '#01B3B2';
    primaryColor2 = '#6049BA';
    prefix = '-lgt';
    brand = 'Logitech';
  } else if (isLUH) {
    primaryColor1 = '#0D1F82';
    primaryColor2 = '#B06ECD';
    prefix = '-luh';
    brand = 'HUL';
  } else if (isCPL) {
    primaryColor1 = '#005ba8';
    primaryColor2 = '#38B08A';
    prefix = '-cpl';
    brand = 'CIPLA';
  } else if (isNFS) {
    primaryColor1 = '#3781C2';
    primaryColor2 = '#DF9927';
    prefix = '-nfs';
    brand = 'Infosys';
  } else if (isIBS) {
    primaryColor1 = '#0c376a';
    primaryColor2 = '#40B6F0';
    prefix = '-ibs';
    brand = 'SBI';
  } else if (isBFG) {
    primaryColor1 = '#2E85C4';
    primaryColor2 = '#47AADF';
    prefix = '-bfg';
    brand = 'Bharat Forge';
  } else if (isACC) {
    primaryColor1 = '#ee2e24';
    primaryColor2 = '#535353';
    prefix = '-acc';
    brand = 'ACC';
  } else if (isNDO) {
    primaryColor1 = '#03174f';
    primaryColor2 = '#2F84DC';
    prefix = '-ndo';
    brand = 'India Oil';
  } else if (isFDB) {
    primaryColor1 = '#004e96';
    primaryColor2 = '#F8A61A';
    prefix = '-fdb';
    brand = 'Federal Bank';
  } else if (isMDL) {
    primaryColor1 = '#0a3c86';
    primaryColor2 = '#E7262B';
    prefix = '-mdl';
    brand = 'Chola';
  } else if (isLIR) {
    primaryColor1 = '#0a3c86';
    primaryColor2 = '#bfa162';
    prefix = '-lir';
    brand = 'RIL';
  } else if (isMCDV2) {
    primaryColor1 = '#FFBC04';
    primaryColor2 = '#2D3277';
    prefix = '-mcd-v2';
    brand = 'MERCADO LIBRE';
  } else if (isTGO) {
    primaryColor1 = '#01214D';
    primaryColor2 = '#43C8F5';
    prefix = '-tgo';
    brand = 'Tigo';
  } else if (isPTRL) {
    primaryColor1 = '#1D7435';
    primaryColor2 = '#fbb200';
    prefix = '-ptrl';
    brand = 'Petrobras';
  } else if (isLISP) {
    primaryColor1 = '#7E5F2A';
    primaryColor2 = '#7E5F2A';
    prefix = '-lisp';
    brand = 'Lindt & Sprüngli';
  } else if (isRDT) {
    primaryColor1 = '#ff4503';
    primaryColor2 = '#ff4503';
    prefix = '-rdt';
    brand = 'Reddit';
  } else if (isRDT2) {
    primaryColor1 = '#E33E01';
    primaryColor2 = '#E33E01';
    prefix = '-rdt2';
    brand = 'Reddit';
  } else if (isSQQM) {
    primaryColor1 = '#6CBF00';
    primaryColor2 = '#6CBF00';
    prefix = '-sqqm';
    brand = 'SQM';
  } else if (isCNDC) {
    primaryColor1 = '#975036';
    primaryColor2 = '#975036';
    prefix = '-cndc';
    brand = 'Codelco';
  } else if (isCNDC2) {
    primaryColor1 = '#975036';
    primaryColor2 = '#975036';
    prefix = '-cndc2';
    brand = 'Codelco';
  } else if (isSHN) {
    primaryColor1 = '#000000';
    primaryColor2 = '#000000';
    prefix = '-shn';
    brand = 'Shein';
  } else if (isBNP) {
    primaryColor1 = '#10357F';
    primaryColor2 = '#00AEEF';
    prefix = '-bnp';
    brand = 'PNB';
  } else if (isJUM) {
    primaryColor1 = '#16B439';
    primaryColor2 = '#16B439';
    prefix = '-jum';
    brand = 'Jumbo';
  } else if (isBDB) {
    primaryColor1 = '#5602AC';
    primaryColor2 = '#5602AC';
    prefix = '-bdb';
    brand = 'BanBajío';
  } else if (isPHO) {
    primaryColor1 = '#ca5001';
    primaryColor2 = '#ca5001';
    prefix = '-pho';
    brand = 'Phoenix';
  } else if (isGLD) {
    primaryColor1 = '#ffc200';
    primaryColor2 = '#eca900';
    textColor = '#000';
    prefix = '-gld';
    brand = 'Gold';
  } else if (isOIL) {
    primaryColor1 = '#000000';
    primaryColor2 = '#eca900';
    prefix = '-oil';
    brand = 'Oil';
  } else if (isGAS) {
    primaryColor1 = '#C0C0C0';
    primaryColor2 = '#aaaaaa';
    textColor = '#000';
    prefix = '-gas';
    brand = 'Natural Gas';
  } else if (isOIL2) {
    primaryColor1 = '#000000';
    primaryColor2 = '#eca900';
    prefix = '-oil2';
    brand = 'Oil';
  } else if (isGLD2) {
    primaryColor1 = '#ffc200';
    primaryColor2 = '#eca900';
    textColor = '#000';
    prefix = '-gld2';
    brand = 'Gold';
  } else if (isGAS2) {
    primaryColor1 = '#C0C0C0';
    primaryColor2 = '#aaaaaa';
    textColor = '#000';
    prefix = '-gas2';
    brand = 'Natural Gas';
  } else if (isCMX) {
    primaryColor1 = '#0012AC';
    primaryColor2 = '#0012AC';
    textColor = '#fff';
    prefix = '-cmx';
    brand = 'CEMEX';
  } else if (isPMN) {
    primaryColor1 = '#00764A';
    primaryColor2 = '#ED1C24';
    textColor = '#fff';
    prefix = '-pmn';
    brand = 'Puma Energy';
  } else if (isANM) {
    primaryColor1 = '#22716d';
    primaryColor2 = '#22716d';
    textColor = '#fff';
    prefix = '-anm';
    brand = 'Antamina';
  } else if (isMAZ) {
    primaryColor1 = '#ff9900';
    primaryColor2 = '#064572';
    textColor = '#fff';
    prefix = '-maz';
    brand = 'Amazon';
  } else if (isARCR) {
    primaryColor1 = '#007cc3';
    primaryColor2 = '#ff5321';
    textColor = '#fff';
    prefix = '-arcr';
    brand = 'Arcor';
  } else if (isDPNS) {
    primaryColor1 = '#2d9950';
    primaryColor2 = '#000000';
    textColor = '#fff';
    prefix = '-dpns';
    brand = 'Dos Pinos';
  } else if (isGRCL) {
    primaryColor1 = '#ec1027';
    primaryColor2 = '#004363';
    textColor = '#fff';
    prefix = '-grcl';
    brand = 'Grupo Clarin';
  } else if (isYPF) {
    primaryColor1 = '#0070c0';
    primaryColor2 = '#0070c0';
    textColor = '#fff';
    prefix = '-ypf';
    brand = 'YPF';
  } else if (isANTM) {
    primaryColor1 = '#369691';
    primaryColor2 = '#e0442e';
    textColor = '#fff';
    prefix = '-antm';
    brand = 'Antofagasta Minerals';
  } else if (isSTD) {
    primaryColor1 = '#ea1d25';
    primaryColor2 = '#ea1d25';
    textColor = '#fff';
    prefix = '-std';
    brand = 'SANTANDER';
  } else if (isCPC) {
    primaryColor1 = '#657e94';
    primaryColor2 = '#657e94';
    textColor = '#fff';
    prefix = '-cpc';
    brand = 'Empresas COPEC';
  } else if (isLPP) {
    primaryColor1 = '#93d500';
    primaryColor2 = '#1d8649';
    textColor = '#fff';
    prefix = '-lpp';
    brand = 'Empresas CMPC';
  } else if (isENM) {
    primaryColor1 = '#01ae9b';
    primaryColor2 = '#F88D2B';
    textColor = '#fff';
    prefix = '-enm';
    brand = 'ENAMI';
  } else if (isLVRP) {
    primaryColor1 = '#e10198';
    primaryColor2 = '#e10198';
    textColor = '#fff';
    prefix = '-lvrp';
    brand = 'Liverpool';
  } else if (isBNT) {
    primaryColor1 = '#ec0928';
    primaryColor2 = '#ec0928';
    textColor = '#fff';
    prefix = '-bnt';
    brand = 'Banorte';
  } else if (isMEC) {
    primaryColor1 = '#0003b1';
    primaryColor2 = '#f72230';
    textColor = '#fff';
    prefix = '-mec';
    brand = 'Cemex';
  } else if (isPCA) {
    primaryColor1 = '#B6885D';
    primaryColor2 = '#6098A2';
    textColor = '#fff';
    prefix = '-pca';
    brand = 'Minera Pampa Camarones';
  } else if (isPRX) {
    primaryColor1 = '#fb9c2c';
    primaryColor2 = '#2327b5';
    textColor = '#fff';
    prefix = '-prx';
    brand = 'Primax';
  } else if (isDIS) {
    primaryColor1 = '#3cb995';
    primaryColor2 = '#FEC02B';
    textColor = '#fff';
    prefix = '-dis';
    brand = 'Walt Disney';
  } else if (isMCS) {
    primaryColor1 = '#0067b8';
    primaryColor2 = '#0b182e';
    textColor = '#fff';
    prefix = '-mcs';
    brand = 'Microsoft';
  } else if (isNVI) {
    primaryColor1 = '#76b900';
    primaryColor2 = '#000000';
    textColor = '#fff';
    prefix = '-nvi';
    brand = 'NVIDIA';
  } else if (isNIK) {
    primaryColor1 = '#ff5000';
    primaryColor2 = '#222';
    textColor = '#fff';
    prefix = '-nik';
    brand = 'Nike';
  } else if (isAPL) {
    primaryColor1 = '#0071e3';
    primaryColor2 = '#222';
    textColor = '#fff';
    prefix = '-apl';
    brand = 'Apple';
  } else if (isVST) {
    primaryColor1 = '#503f82';
    primaryColor2 = '#C2B280';
    textColor = '#fff';
    prefix = '-vst';
    brand = 'Vista Energy';
  } else if (isGRC) {
    primaryColor1 = '#092F57';
    primaryColor2 = '#092F57';
    textColor = '#fff';
    prefix = '-grc';
    brand = 'Grupo Carso';
  } else if (isGMA) {
    primaryColor1 = '#00663E';
    primaryColor2 = '#00663E';
    textColor = '#fff';
    prefix = '-gma';
    brand = 'Gruma';
  } else if (isSOG) {
    primaryColor1 = '#ff9900';
    primaryColor2 = '#052c36';
    textColor = '#fff';
    prefix = '-sog';
    brand = 'SolGold';
  } else if (isSLR) {
    primaryColor1 = '#8a5623';
    primaryColor2 = '#267e8d';
    textColor = '#fff';
    prefix = '-slr';
    brand = 'Solaris Resources';
  } else if (isPTRB) {
    primaryColor1 = '#155592';
    primaryColor2 = '#01a467';
    textColor = '#fff';
    prefix = '-ptrb';
    brand = 'PetroBal';
  } else {
    primaryColor1 = '#ED8000';
    primaryColor2 = '#D00000';
    prefix = '';
    brand = 'GRUPO LOGO';
  }

  return (
    <>
      <GoogleFonts fontsLink='https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap' />
      <GlobalStyles />
      <Wrapper
        primaryColor1={primaryColor1}
        primaryColor2={primaryColor2}
        textColor={textColor}>
        <LazyHydrate whenVisible>
          <Top
            scrollToCalc={scrollToCalc}
            prefix={prefix}
            removeRangeSlider={removeRangeSlider}
            primaryColor1={primaryColor1}
          />
        </LazyHydrate>
        <LazyHydrate whenVisible>
          <Quotes prefix={prefix} />
        </LazyHydrate>
        <LazyHydrate whenIdle>
          <Form formRef={formRef} brand={brand} />
        </LazyHydrate>
        {addCalculator ? (
          <LazyHydrate whenIdle>
            <Calculator calcRef={calcRef} />
          </LazyHydrate>
        ) : (
          !removeRangeSlider && (
            <LazyHydrate whenIdle>
              <RangeSlider calcRef={calcRef} handleScroll={scrollToForm} />
            </LazyHydrate>
          )
        )}
        {addArticle && (
          <LazyHydrate whenIdle>
            <Article prefix={prefix} handleScroll={scrollToForm} />
          </LazyHydrate>
        )}
        {!removeFeatures && (
          <LazyHydrate ssrOnly>
            <Features />
          </LazyHydrate>
        )}
        {!removeCTA && (
          <LazyHydrate whenVisible>
            <CTA scrollToForm={scrollToForm} brand={brand} />
          </LazyHydrate>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  overflow-x: hidden;

  --primary-color-1: ${({ primaryColor1 }) => primaryColor1 && primaryColor1};
  --primary-color-2: ${({ primaryColor2 }) => primaryColor2 && primaryColor2};
  --text-color: ${({ textColor }) => textColor};
`;

const GlobalStyles = createGlobalStyle`
  body {
    font-family: "Montserrat",sans-serif;
    line-height: 1.26;
    background: #fff;
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
    display: block;
  }
`;

export default App;
